$primary: rgb(71, 66, 36);
$primary-trans: rgba(71, 66,36, 0.5);
$secondary: rgb(141, 124, 82);
$warning: rgb(231, 167, 87);;
$danger: rgb(180, 185, 164);
$success: rgb(161, 224, 161);
$info: cyan;
$facebook: #1DA1F2;
$light: white;
$dark: black;
$light-gray: rgb(211, 204, 188);
$light-gray-trans: rgba(162, 144, 99, .75);
$grullo: #B3A394;
$transparent: #10254200;

$theme-colors: (
    'primary': $primary,
    'primary-trans': $primary-trans,
    'transparent': $transparent,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'light-gray': $light-gray,
    'grullo': $grullo,
    'facebook' :$facebook,
    'light-gray-trans': $light-gray-trans
);

@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

html *
{
   font-family: "Arial", serif;
}