.foo {
    background-color: theme-color("info");
}

.nounderline {
    text-decoration: none !important
}

.open-sans-font {
  font-family: "Open Sans", serif;
}

.no-list-style li {
  list-style-type: none;
}

.pointer-hand {
  cursor:pointer;
}

.highlight-text {
  text-shadow: 1px 1px $primary;
}

.blur {
  backdrop-filter: blur(1.5px);
}

/* Styles for screens smaller than 600px */
@media only screen and (max-width: 600px) {
	.logo-text {
			font-size: 0.55rem;
	}
}

/* Styles for screens between 600px and bigger x */
@media only screen and (min-width: 600px)  {
	.logo-text {
			font-size: 1rem;
	}
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.bold {
	font-weight: bold;
}